.comp-hero-banner__row {
  background-color: var(--color-blue-200);
  border-radius: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2em;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .comp-hero-banner {
    gap: 2.5em;
  }
}
.comp-hero-banner__title {
  margin-bottom: 1em;
}
.comp-hero-banner__title h1 {
  font-size: 2.625rem;
  line-height: 1.25;
  text-align: center;
}
@media (max-width: 1023px) {
  .comp-hero-banner__title h1 {
    font-size: 2rem;
  }
}
.comp-hero-banner__subtitle {
  margin-bottom: 2em;
  color: var(--color-blue-1100);
}
.comp-hero-banner__subtitle p,
.comp-hero-banner__subtitle span {
  text-align: center;
  font-size: 1.125em;
  line-height: 1.35;
}
@media (max-width: 1023px) {
  .comp-hero-banner__subtitle p,
  .comp-hero-banner__subtitle span {
    font-size: 0.875em;
  }
}
.comp-hero-banner__btn {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 1.5em;
}
@media (max-width: 1279px) {
  .comp-hero-banner__btn {
    flex-direction: column;
  }
  .comp-hero-banner__btn a[class^=cta] {
    width: 100%;
    justify-content: center;
  }
}
.comp-hero-banner__link {
  text-align: center;
  margin: 1.5em 0;
}
.comp-hero-banner__col {
  position: relative;
}
@media (min-width: 1024px) {
  .comp-hero-banner__col {
    flex: 1;
  }
}
.comp-hero-banner__wrapper {
  padding: 9em 0;
  flex: 1.5;
}
@media (max-width: 1279px) {
  .comp-hero-banner__wrapper {
    padding: 3em 1em;
  }
}
@media (max-width: 1279px) {
  .comp-hero-banner__img {
    display: none;
  }
}
.comp-hero-banner__img-top {
  position: absolute;
  top: 0;
  right: 0;
}
.comp-hero-banner__img-left {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.comp-hero-banner__img-right {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media (prefers-reduced-motion: no-preference) {
  @supports (animation-timeline: scroll()) {
    @media (prefers-reduced-motion: no-preference) and (min-width: 1280px) {
      div[class*=comp-hero-banner__img-] {
        animation-timeline: scroll(root);
        animation-range: 0 300px;
        animation-timing-function: linear;
        animation-fill-mode: both;
      }
      .comp-hero-banner__img-top {
        animation-name: anim-img-top;
      }
      .comp-hero-banner__img-left {
        animation-name: anim-img-left;
      }
      .comp-hero-banner__img-right {
        animation-name: anim-img-right;
      }
      @keyframes anim-img-top {
        to {
          transform: translateY(-100%);
        }
      }
      @keyframes anim-img-left {
        to {
          transform: translateX(-100%);
        }
      }
      @keyframes anim-img-right {
        to {
          transform: translateX(100%);
        }
      }
    }
  }
}
@media (prefers-reduced-motion: reduce) {
  div[class*=comp-hero-banner__img-] {
    animation: none !important;
    transition: none !important;
    transform: none !important;
  }
}